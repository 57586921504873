import React from 'react';

function GetStarted() {
  return (
    <section id="get-started">
      <div className="container">
        <h2>Ready to Transform Healthcare?</h2>
        <p>Join AroTrust today and be part of the future of secure and transparent healthcare management with our cutting-edge solutions.</p>
        <p>Reach us at: 
          <a href="mailto:info@arotrust.com?subject=Inquiry%20from%20website&body=Please%20write%20your%20message%20here.">
            info@arotrust.com
          </a>
        </p>
      </div>
    </section>
  );
}

export default GetStarted;