import React from 'react';

function Features() {
  return (
    <section id="features" className="features">
      <div className="container">
        <h2>Key Features</h2>
        <div className="feature-box">
          <div className="feature-item">
            <h3>Security</h3>
            <p>Our EMR system ensures that medical data is encrypted, preventing unauthorized access and maintaining confidentiality.</p>
          </div>
          <div className="feature-item">
            <h3>Transparency</h3>
            <p>Our solution offers complete transparency, where every action is auditable, traceable, and reliable.</p>
          </div>
          <div className="feature-item">
            <h3>Decentralization</h3>
            <p>With decentralized architecture, health records are no longer stored in a single location, mitigating the risks of data breaches.</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Features;