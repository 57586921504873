import React from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import Features from './components/Features';
import Solutions from './components/Solutions';
import GetStarted from './components/GetStarted';
import Footer from './components/Footer';


function App() {
  return (
    <div className="App">
      <Header />
      <div className="main-content"> {/* Wrap main content to adjust for the sidebar */}
        
        <div className="content"> {/* Add content class for layout adjustment */}
          <Hero />
          <Features />
          <Solutions />
          <GetStarted />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;