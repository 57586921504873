import React from 'react';

function Hero() {
  return (
    <section id="hero">
      <div className="hero-container">
        <div className="login-box">
          <h2>Login</h2>
          <form action="#" method="post">
            <div className="input-container">
              <label htmlFor="login-id">Login ID</label>
              <input type="text" id="login-id" name="login-id" required />
            </div>
            <div className="input-container">
              <label htmlFor="password">Password</label>
              <input type="password" id="password" name="password" required />
            </div>
            <div className="remember-me">
              <input type="checkbox" id="remember-me" name="remember-me" />
              <label htmlFor="remember-me">Remember Me</label>
            </div>
            <button type="submit" className="cta-btn">Login</button>
          </form>
        </div>

        <div className="video-container">
          <video autoPlay muted loop id="heroVideo">
            <source src="assets/videos/classy-background.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="overlay-text">
            <h2>AroTrust: Secure EMR</h2>
            <p>Empowering healthcare with advanced infrastructure and security.</p>
            <a href="#get-started" className="cta-btn hero-btn">Discover the Solutions</a>
          </div>
        </div>
      </div>

      <div className="marketing-icons">
        <div className="icon-item">
          <img src="assets/images/icon-product.png" alt="Product Icon" />
          <h3>Data Privacy</h3>
          <p>Empower patients with full control over their medical data using blockchain technology.</p>
        </div>
        <div className="icon-item">
          <img src="assets/images/icon-claim.png" alt="Claim Icon" />
          <h3>Interoperability</h3>
          <p>Ensure smooth data sharing between healthcare providers while maintaining data integrity.</p>
        </div>
        <div className="icon-item">
          <img src="assets/images/icon-security.png" alt="Security Icon" />
          <h3>Auditability</h3>
          <p>Provide a transparent, auditable history of data access and changes, ensuring compliance.</p>
        </div>
      </div>
    </section>
  );
}

export default Hero;