import React from 'react';

function Footer() {
  return (
    <footer>
      <div className="footer-container">
        <div className="logo-footer">
          <img src="assets/images/logo.png" alt="AroTrust Logo" />
        </div>
        <ul className="footer-links">
          <li><a href="#about">About Us</a></li>
          <li><a href="#features">Features</a></li>
          <li><a href="#solutions">Solutions</a></li>
          <li><a href="#contact">Contact</a></li>
        </ul>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 AroTrust. All Rights Reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;