import React from 'react';

function Solutions() {
  return (
    <section id="solutions" className="solutions">
      <div className="container">
        <h2>Our Blockchain EMR Solutions</h2>
        <div className="solution-box">
          <div className="solution-item">
            <h3>For Patients</h3>
            <p>Access your medical records anytime, anywhere with complete control over who views your data.</p>
          </div>
          <div className="solution-item">
            <h3>For Healthcare Providers</h3>
            <p>Streamline patient data sharing, reduce paperwork, and ensure compliance with global health regulations.</p>
          </div>
          <div className="solution-item">
            <h3>For Researchers</h3>
            <p>Utilize accurate and immutable data for healthcare research while ensuring data privacy and integrity.</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Solutions;